import React from "react"
import Layout from "../components/layout"
import {
  Title,
  Overview,
  Toolset,
  Project,
  Involvement,
  Car,
} from "../components/parts"

const Learnographics = () => {
  return (
    <Layout title="NUS GRIP">
      <Title heading="NUS GRIP" sub="Prototypes for 'deep-tech' startups" />
      <Overview
        project="nus-grip"
        text={[
          "I built the early-stage prototypes for the startups that went through the Graduate Research Innovation Programme at the National University of Singapore.",
        ]}
      />
      <Project
        heading="Prognotron"
        sub="Optimisation for chiller plants"
        style={{ marginBottom: 0 }}
      />
      <div className="section-bottom">
        <Car
          images={[1, 2, 3].map(i => `/images/nus-grip/prog-${i}.png`)}
          bordered
        />
      </div>
      <Toolset tools={["reactjs"]} />
      <Involvement involved={["Ideation", "Front-end"]} />
      <Project
        heading="Dravam"
        sub="Streaming sensor data for oil transfer from ship to dock"
        style={{ marginBottom: 0 }}
      />
      <div className="section-bottom">
        <video src="/images/nus-grip/dravam.mp4" alt="demo" autoPlay controls>
          <track kind="captions" />
        </video>
      </div>
      <Toolset tools={["reactjs"]} />
      <Involvement involved={["Ideation", "Front-end"]} />
    </Layout>
  )
}

export default Learnographics
